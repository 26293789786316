/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from "next/dynamic";
import Image from "next/image";

// images
import logo from "/assets/img/logo-with-padding.png"

// Pro
const MDAvatar = dynamic(() => import('/components/MDAvatar'), { loading: () => null });

function ALLDAvatar() {
  return (
    <MDAvatar
      alt="Autumn Leaf"
      shadow="md"
      variant="circular"
    >
      <Image src={logo.src || null} alt="Autumn Leaf Landscape Design logo" width={48} height={48} />
    </MDAvatar>
  );
}

export default ALLDAvatar;
